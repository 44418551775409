export const pages = [
  { index: 0, name: 'overview.md', route: '/overview' },
  { index: 1, name: 'skills.md', route: '/skills' },
  { index: 2, name: 'experience.md', route: '/experience' },
  { index: 3, name: 'education.md', route: '/education' },
  { index: 4, name: 'projects.md', route: '/projects' },
  { index: 5, name: 'coursework.md', route: '/coursework' },
  { index: 6, name: 'accomplishments.md', route: '/accomplishments' },
  { index: 7, name: 'books.md', route: '/books' },
];
